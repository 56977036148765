<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>Tournament wins over time</h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '4' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getWinningTeamsHurling" v-model="overallTeamSelections"
                                        @change="overallTeamSelectionsChanged" multiple clearable placeholder="All"
                                        label="Teams" item-text="name" return-object outlined hide-details hide-no-data>
                                        <template #selection="{ item }">
                                            <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallTeamSelections[0].name">{{
                                                overallTeamSelections.length }} teams</span>
                                        </template>
                                    </v-select>
                                    <v-combobox v-else :items="getWinningTeamsHurling" v-model="overallTeamSelections"
                                        @change="overallTeamSelectionsChanged" multiple clearable placeholder="All"
                                        label="Teams" item-text="name" return-object outlined hide-details hide-no-data>
                                        <template #selection="{ item }">
                                            <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallTeamSelections[0].name">{{
                                                overallTeamSelections.length }} teams</span>
                                        </template>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearFromFilters"
                                        v-model="overallTotalsYearFromFilter"
                                        @change="overallTotalsYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearFromFilters"
                                        v-model="overallTotalsYearFromFilter"
                                        @change="overallTotalsYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearToFilters" v-model="overallTotalsYearToFilter"
                                        @change="overallTotalsYearToFilterChanged" label="Year to" outlined hide-details
                                        hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearToFilters" v-model="overallTotalsYearToFilter"
                                        @change="overallTotalsYearToFilterChanged" label="Year to" outlined hide-details
                                        hide-no-data></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                        <div v-if="getWinningResultsLineData.data.length == 0" class="caption">Select at least one team
                            to populate graph</div>
                    </v-card-title>
                    <v-card-text>
                        <div id="line-chart-share" v-if="getWinningResultsLineData.data.length > 0" class="chartBackground">
                            <apexchart type="line" height="450" :options="lineChartOptions"
                                :series="getWinningResultsLineData.data">
                            </apexchart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    name: 'RollOfHonour',
    components: {
        StatsCard
    },
    async mounted() {
        await this.fetchWinners(this.$router.currentRoute.meta.sport.toUpperCase());
        this.loading = false;

    },
    data() {
        return {
            loading: true,
            overallTeamSelections: [],
            overallWinnerYearFromFilter: 'All',
            overallWinnerYearToFilter: 'All',
            overallTotalsYearFromFilter: 'All',
            overallTotalsYearToFilter: 'All',
            overallCompetitionFilter: 'All Ireland',
        }
    },
    computed: {
        ...mapGetters(['getWinningResults', 'getWinningTeamsHurling', 'getWinners', 'getWinningResultsLineData']),
        lineChartOptions() {
            return {
                chart: {
                    id: 'line-chart'
                },
                xaxis: {
                    categories: this.getWinningResultsLineData.years
                },
                colors: this.getWinningResultsLineData.data.map(it => it.colour),
                tooltip: {
                    x: {
                        format: 'yyyy'
                    },
                }
            }
        },
        overallWinnerYearToFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => b - a));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => a - b));
            return years;
        },

    },
    methods: {
        ...mapActions(['fetchWinners', 'updateOverallTeamSelections', 'updateOverallTotalsYearFromFilter',
            'updateOverallTotalsYearToFilter', 'updateOverallWinnerYearFromFilter', 'updateOverallWinnerYearToFilter', 'updateOverallCompetitionFilter']),
        overallTeamSelectionsChanged() {
            this.updateOverallTeamSelections(this.overallTeamSelections);
        },
        overallTotalsYearFromFilterChanged() {
            this.updateOverallTotalsYearFromFilter(this.overallTotalsYearFromFilter);
        },
        overallTotalsYearToFilterChanged() {
            this.updateOverallTotalsYearToFilter(this.overallTotalsYearToFilter);
        },
        overallCompetitionFilterChanged() {
            this.updateOverallCompetitionFilter(this.overallCompetitionFilter);
        }
    }
}
</script>

<style>
.chartBackground {
    background-image: url('/assets/logo/ktg_green_fade.svg');
    background-size: contain;
    background-position: center;
}
</style>